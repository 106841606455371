import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonAlert
} from "@ionic/react";
import {
    attach,
    chevronForward,
    createOutline,
    informationCircle,
    informationCircleOutline,
    pencil
} from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {api, createPostPurchaseTicket, createTicket} from "../../../api";
import { Header } from "../../../components/Header";
import { IonBtn } from "../../../components/IonBtn";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

const data = {
    "Arredo Bagno": [
        "Sistemi e meccanismi di apertura",
        "Ante",
        "Struttura Mobili",
        "Top Lavabo",
        "Maniglie",
        "Box Doccia",
        "Illuminazione",
        "Idraulica",
        "Accessori",
    ],
    "Arredo Cucina": [
        "Sistemi e meccanismi di apertura",
        "Ante",
        "Elettrodomestici",
        "Struttura Mobili",
        "Piano Lavoro",
        "Maniglie",
        "Illuminazione",
        "Idraulica",
        "Accessori mobili",
    ],
    "Arredo Zona giorno e notte": [
        "Sistemi e meccanismi di apertura",
        "Ante",
        "Struttura Mobili",
        "Maniglie",
        "Illuminazione",
        "Accessori",
        "Rivestimenti e finiture",
    ],
    "Decorazioni": [
        "Danni",
        "Rotture e malfunzionamenti",
    ],
    "Illuminazione": [
        "Danni",
        "Rotture e malfunzionamenti",
    ],
}

export const PostVendita = ({ match }) => {

    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;
    const { apartment, setApartment } = useContext(AuthContext);
    const [completedSaleOrders, setCompletedSaleOrders] = useState();
    const history = useHistory();
    const [hasDocuments, setHasDocuments] = useState(null);

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');
        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId;

        api.get(url, { withCredentials: true })
            .then(({ data: apartment }) => {
                brandService.setData(apartment.residence);
                setApartment(apartment);
            })
    }, []);

    useEffect(() => {
        if (apartment) {

            if (!!apartment.sale_order?.length) {
                apartment.sale_order.map((sale_order) => {
                    if (sale_order.documents && !!Object.keys(sale_order.documents).length) setHasDocuments(true);
                })
                const completedSaleOrders = [];
                for (let saleOrder of apartment.sale_order) {
                    if (saleOrder.state >= 5) {
                        completedSaleOrders.push(saleOrder);
                    }
                }
                setCompletedSaleOrders(completedSaleOrders);
            }

        }
    }, [apartment]);


    const [visible, setVisible] = useState(false);
    const input = useRef();
    const [ticketName, setTicketName] = useState(null);
    const [saleOrder, setSaleOrder] = useState('default');
    const [description, setDescription] = useState('');
    const [sendingForm, setSendingForm] = useState(false);
    const overviewInput = useRef();
    const [overviewBase64, setOverviewBase64] = useState(false);
    const detailInput = useRef();
    const [detailBase64, setDetailBase64] = useState(false);
    // La concatenazione dei due seguenti campi è l'oggetto del ticket
    const [selectedArea, setSelectedArea] = useState('default');
    const [selectedDamage, setSelectedDamage] = useState(null);
    const [tickets, setTickets] = useState(null);
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        if (data[selectedArea] && selectedDamage !== null) {
            setTicketName("Assistenza: " + data[selectedArea][selectedDamage] + " " + selectedArea);
        } else {
            setTicketName(null);
        }
    }, [selectedArea, selectedDamage]);


    useEffect(() => {
        setSelectedDamage(null);
    }, [selectedArea]);

    const selectDamage = (index) => {
        setSelectedDamage(index);
        setVisible(false);
    }


    useEffect(() => {
        if (apartmentId) {
            retrieveAllTickets().then((data) => {

                console.log(data);
                setTickets(data.data.rows);
            });
        }
    }, [apartmentId]);


    const sessionId = localStorage.getItem('sessionId');

    const retrieveAllTickets = async () => {
        let url = '/mcd_helpdesk_api/private/v2/helpdesk-post-purchase/?apartment_id=' + apartmentId + "&post_purchase=true";
        try {
            return await api.get(url + '&session_id=' + sessionId, { withCredentials: true })
        } catch (e) {
            console.log(e)
            return e;
        }
    };

    const sendForm = async () => {
        setSendingForm(true);
        let files = [];
        if (overviewBase64) {
            files.push(overviewBase64.replace(/^data:image\/[a-z]+;base64,/, "").replace(/^data:application\/[a-z]+;base64,/, ""));
        }

        if (detailBase64) {
            files.push(detailBase64.replace(/^data:image\/[a-z]+;base64,/, "").replace(/^data:application\/[a-z]+;base64,/, ""));
        }

        const reqBody = {
            followers: null,
            user_id: Number(apartment.residence.assistenza_user_id),
            real_estate_units_id: Number(apartment.id),
            residence_id: Number(apartment.residence.id),
            so_id: Number(saleOrder),
            name: ticketName,
            description,
            files,
            // post_sale_reporting_team: true
        }

        try {
            const { data } = await createPostPurchaseTicket(reqBody);
            setSaleOrder('default');
            setSelectedArea('default');
            setSelectedDamage(null);
            setDescription('');
            setDetailBase64(null);
            setOverviewBase64(null);

            setShowModal(false);
            retrieveAllTickets().then(({ data }) => {
                setTickets([...data.rows]);
            });
        } catch (err) {
            alert(err);
        } finally {
            setSendingForm(false);
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleOverviewInput = async (e) => {
        setOverviewBase64(await toBase64(e.target.files[0]));
    }

    const handleDetailInput = async (e) => {
        setDetailBase64(await toBase64(e.target.files[0]));
    }

    const [orderToShow, setOrderToShow] = useState("all");
    const [present] = useIonAlert();

    if (!apartment) return (

        <IonPage>
            <Header />
            <IonContent color="light">
                <IonSpinner />
            </IonContent>
        </IonPage>
    )
    return (
        <IonPage>
            <Header />
            <IonContent color="light">

                <div className="p-3">

                    <h2 className="mb-1">Assistenza</h2>
                    <p className="text-md mb-1">

                        <IonIcon icon={informationCircle} className={"me-1 mt-1 opacity-75"} />
                        Prima di aprire una Nuova richiesta di Assistenza assicurati che non sia già presente tra le
                        Richieste in gestione.
                        In quel caso, consigliamo di scrivere direttamente nella Richiesta in gestione.
                    </p>


                    <IonBtn color="dark" expand="block" className="mb-3 mt-2" onClick={() => {

                        if (!apartment.worksheet_status) {

                            present({
                                header: 'Attenzione',
                                message: 'Assicurati di aver compilato tutti i campi obbligatori (contrassegnati da *) prima di continuare',
                                buttons: [
                                    { text: 'Ok', handler: (d) => console.log('ok pressed') },
                                ],
                            })

                        } else {

                            setShowModal(true);
                        }
                    }}>
                        <IonIcon mode="ios" icon={createOutline} className="me-1" /> Nuova richiesta
                    </IonBtn>


                    <h5 className="mt-3 pt-3">Richieste in gestione</h5>
                    <div className={"mb-4 d-flex"}>
                        <div className="w-50 pe-2">
                            <span className={"text-md fw-bold"}>Tipologia</span>
                            <IonSelect size="small" className="bg-white rounded shadow-sm" placeholder="Seleziona"
                                value={orderToShow} onIonChange={e => setOrderToShow(e.detail.value)}>
                                <IonSelectOption value="all">Tutte</IonSelectOption>
                                <IonSelectOption value="mcd">Da verbale</IonSelectOption>
                                <IonSelectOption value="postSale">Post Consegna</IonSelectOption>
                            </IonSelect>
                        </div>
                    </div>


                    {
                        tickets ?
                            !!tickets.length && tickets.map(({
                                id,
                                name,
                                stage,
                                consultant = { name: 'Assistenza post vendita' },
                                worksheet_id
                            }, index) =>
                            (
                                (orderToShow === "all" || (orderToShow === "mcd" && worksheet_id) || (orderToShow === "postSale" && !worksheet_id)) &&
                                <IonBtn key={id}
                                    onClick={() => history.push("/residence/" + residenceId + "/apartment/" + apartmentId + "/comunicazioni/" + id)}
                                    color="white" expand="block" className="d-flex shadow-sm mb-3 px-0 h-100">
                                    <div
                                        className="d-flex justify-content-between ion-text-left w-100 my-3 d-block align-items-center">
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={process.env.REACT_APP_API + "/mcd_profile_api/private/image/" + consultant.id + "?session_id=" + sessionId}
                                                alt=""
                                                onError={(e) => {
                                                    e.target.src = "/assets/img/icons/user-placeholder.png"
                                                }}
                                                className="rounded-circle" width="40" height="40" />

                                            <div className="px-3 ion-text-wrap">
                                                <div
                                                    className="mb-1 text-small opacity-75">{worksheet_id ? "Da verbale" : "Post consegna"}</div>

                                                <div className={"h5 font-weight-bold mb-1"}>
                                                    {name}
                                                </div>

                                                <p className="mb-0 opacity-50">{consultant.name}</p>
                                                {/* <p className="mb-0 opacity-50">{consultant.name}</p> */}

                                            </div>
                                        </div>
                                        <IonIcon icon={chevronForward} className="opacity-50" slot="end" />
                                    </div>
                                </IonBtn>
                            )
                            )
                            :
                            <IonSpinner />

                    }


                    <IonModal isOpen={showModal}
                        onDidDismiss={() => setShowModal(false)}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Nuova richiesta</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={() => setShowModal(false)}>
                                        Chiudi
                                    </IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="p-3">

                            <div className="px-3 pb-3">
                                <div className={"mt-3 pt-3"}>
                                    <label className={"d-block"}>Residenza</label>
                                    <IonSelect className="ps-2 bg-light rounded" placeholder="Seleziona una residenza"
                                        value={apartment.residence.id || null}>
                                        {
                                            (apartment.residence) &&
                                            <IonSelectOption value={apartment.residence.id}>
                                                {apartment.residence.name}
                                            </IonSelectOption>

                                        }
                                    </IonSelect>
                                </div>
                                <div className={"mt-3 pt-3"}>
                                    <label className={"d-block"}>Unità Immobiliare</label>
                                    <IonSelect className="ps-2 bg-light rounded" placeholder="Seleziona una residenza"
                                        value={apartment.id || null}>
                                        {
                                            (apartment.name && apartment.id) &&
                                            <IonSelectOption value={apartment.id}>{apartment.name}</IonSelectOption>

                                        }
                                    </IonSelect>
                                </div>
                                <div className={"mt-4"}>
                                    <label className={"d-block"}>Ordine di vendita</label>
                                    <IonSelect className="ps-2 bg-light rounded"
                                        placeholder="Seleziona un ordine di vendita"
                                        value={saleOrder} onIonChange={e => setSaleOrder(e.target.value)}>
                                        {
                                            !!completedSaleOrders?.length && completedSaleOrders.map(({
                                                id,
                                                home_j_type
                                            }) =>
                                                <IonSelectOption key={id}
                                                    value={id}>{home_j_type.trim() === "Extra Furniture" ? "Arredo Extra" : home_j_type}</IonSelectOption>
                                            )
                                        }
                                    </IonSelect>
                                </div>
                                <div className={"mt-4"}>
                                    <label className={"d-block"}>Ambito Arredo</label>
                                    <IonSelect className="ps-2 bg-light rounded" value={selectedArea}
                                        onIonChange={e => setSelectedArea(e.target.value)}
                                        placeholder="Seleziona un Ambito Arredo">
                                        {
                                            Object.keys(data).map((key, index) =>
                                                <IonSelectOption key={index} value={key}>{key}</IonSelectOption>)
                                        }
                                    </IonSelect>
                                </div>
                                <div className={"mt-4 w-100"}>
                                    <label className={"d-block"}>Problematica per cui si richiede l'intervento</label>
                                    <IonBtn color="light" expand="block" disabled={!data[selectedArea]}
                                        onClick={() => setVisible(true)}>
                                        {
                                            (selectedDamage !== null && data[selectedArea]) ?
                                                <>
                                                    <span>
                                                        {data[selectedArea][selectedDamage]}
                                                    </span>
                                                    <IonIcon icon={pencil} className={"ps-2"} />
                                                </>
                                                :
                                                "Seleziona una problematica"
                                        }
                                    </IonBtn>
                                </div>

                                <div className={"mt-4"}>
                                    <div className={"row mb-3"}>
                                        <div className={"col-md-6 mb-3"}>
                                            <label className={"d-block"}>Vista intera del prodotto interessato</label>

                                            <input type={"file"} onChange={handleOverviewInput} className={"d-none"}
                                                ref={overviewInput} />
                                            <IonBtn color="light" onClick={() => overviewInput.current.click()}
                                                className={"d-flex flex-nowrap align-items-center"}>
                                                {
                                                    overviewBase64 ?
                                                        <span className={"mr-2 text-nowrap"}>1 file</span>
                                                        :
                                                        <span className={"mr-2 text-nowrap"}>Scegli file</span>
                                                }
                                                <IonIcon icon={attach} className={"op-7"} />
                                            </IonBtn>
                                            {
                                                overviewBase64 &&
                                                <span onClick={() => setOverviewBase64(null)}
                                                    className={"btn btn-link px-0"}>Rimuovi</span>
                                            }
                                        </div>
                                        <div className={"col-md-6 mb-3"}>
                                            <label className={"d-block"}>Vista di dettaglio del prodotto</label>

                                            <input type={"file"} onChange={handleDetailInput} className={"d-none"}
                                                ref={detailInput} />
                                            <IonBtn color="light" onClick={() => detailInput.current.click()}
                                                className={"d-flex flex-nowrap align-items-center"}>
                                                {
                                                    detailBase64 ?
                                                        <span className={"mr-2 text-nowrap"}>1 file</span>
                                                        :
                                                        <span className={"mr-2 text-nowrap"}>Scegli file</span>
                                                }
                                                <IonIcon icon={attach} className={"op-7"} />
                                            </IonBtn>
                                            {
                                                detailBase64 &&
                                                <span onClick={() => setDetailBase64(null)}
                                                    className={"btn btn-link px-0"}>Rimuovi</span>
                                            }
                                        </div>
                                    </div>
                                    <div className={"text-muted"}>
                                        <IonIcon icon={informationCircle} className={"text-muted mr-2"} />
                                        E’ possibile caricare un file con le seguenti estensioni: .jpg
                                        .jpeg .bmp .gif .png .doc .docx .mpeg .avi .pdf avente una dimensione massima di
                                        20
                                        Mbyte
                                    </div>

                                </div>

                                <div className={"mt-4"}>
                                    <label className={"d-block"}>Messaggio</label>
                                    <IonTextarea value={description} onIonChange={e => setDescription(e.target.value)}
                                        placeholder={"Descrivi la problematica..."}
                                        className={"w-100 rounded border px-2"} />
                                </div>
                                <div className="mx-3 text-md my-3 bg-light rounded p-2 d-flex align-items-center">
                                    <span className="d-flex" style={{ fontSize: 20 }}>
                                        <IonIcon icon={informationCircleOutline} className="me-1" />
                                    </span>
                                    Le comunicazioni presenti in questa sezione sono
                                    accessibili a Contract District Group e alla committenza della Residenza
                                </div>
                                <IonBtn expand="block" color="dark"
                                    disabled={(!detailBase64 || !overviewBase64 || !apartment || !ticketName || !description || (saleOrder === 'default') || sendingForm)}
                                    onClick={sendForm} className={"mt-4 rounded w-100"}>
                                    {
                                        sendingForm &&
                                        <IonSpinner />
                                    }
                                    Invia Segnalazione
                                </IonBtn>
                                <input type={"file"} className={"d-none"} ref={input} />
                            </div>
                        </IonContent>
                    </IonModal>
                </div>

            </IonContent>


            <IonModal isOpen={visible} onDidDismiss={() => setVisible(false)}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            Seleziona problematica
                        </IonTitle>

                        <IonButtons slot="end">
                            <IonButton onClick={() => setVisible(false)}>
                                Chiudi
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>


                <IonContent>

                    <div className={"d-grid g-1-1-1-1 p-3"}>
                        {
                            (selectedArea && data[selectedArea]) && data[selectedArea].map((damageName, index) =>
                                <IonBtn onClick={() => selectDamage(index)}
                                    color={index === selectedDamage ? "dark" : "light"} expand="block"
                                    className="h-100">
                                    <div
                                        className="d-flex my-3 flex-column justify-center align-items-center pt-2 text-wrap">
                                        <img src={"/assets/img/icons/svg/" + (damageName.toLowerCase()) + ".svg"}
                                            alt=""
                                            width={50}
                                            height={50}
                                            onError={(e) => {
                                                e.target.src = "/assets/img/icons/svg/sistemi.svg"
                                            }}
                                        />
                                        <div className={"text-center my-2"}>{damageName}</div>
                                    </div>
                                </IonBtn>
                            )
                        }

                    </div>

                </IonContent>

            </IonModal>

        </IonPage>
    )
}
